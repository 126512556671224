<div class="container h-100 d-flex align-items-center justify-content-between flex-column flex-sm-row">
  <a class="pt-5 pb-4 p-sm-0" routerLink="">
    <img class="logo" src="/assets/images/schick-software-inverted.svg" alt="Logo Schick Software" width="280">
  </a>
  <div class="d-none d-md-block text-center">
    <span class="text-secondary w-100">Design by </span>
    <br class="d-block d-lg-none">
    <a class="" href="https://matze.dev/" target="_blank">Matthias Seifert</a>
  </div>
  <div class="d-flex flex-column flex-lg-row pt-4 pb-5 p-sm-0">
    <a routerLink="privacy" fragment="legal" class="d-inline me-3 me-xl-5">Impressum</a>
    <a routerLink="privacy" fragment="statement" class="d-inline me-3 me-xl-5">Datenschutz</a>
    <a class="d-none d-lg-inline" href="https://get.anydesk.com/v9H2EqLu/Schick_Remote.exe">Fernwartung</a>
  </div>
</div>
