import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './components/main/main.component';
import {PrivacyComponent} from './components/privacy/privacy.component';
import {ProjectsTimeTrackingComponent} from "./components/main/projects-time-tracking/projects-time-tracking.component";
import {ProjectsKeycloakAspComponent} from "./components/main/projects-keycloak-asp/projects-keycloak-asp.component";
import {
  ProjectsFilterCreatorComponent
} from "./components/main/projects-filter-creator/projects-filter-creator.component";

const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      {path: '', component: ProjectsTimeTrackingComponent},
      {path: 'time-tracking', component: ProjectsTimeTrackingComponent},
      {path: 'asp-keycloak', component: ProjectsKeycloakAspComponent},
      {path: 'filter-expression-creator', component: ProjectsFilterCreatorComponent},
    ]
  },
  {
    path: 'privacy', component: PrivacyComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollOffset: [0, -100], // [x, y]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
