<div class="background">
  <div class="container">
    <div class="title">
      <h1 class="highlight">Projekte</h1>
    </div>

    <div class="row row-cols-2 content">
      <div class="col-11">
        <router-outlet></router-outlet>
      </div>

      <div class="col-11 offset-2">
        <div class="row row-cols-1 g-5">

          <a class="col project text-dark text-decoration-none" routerLink="" fragment="projects" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <div class="row h-100">
              <div class="col text">
                <h3>TimeTracking</h3>
                <p>Ein schnelles und unkompliziertes Zeiterfassungs-Tool.</p>
                <div class="more">&lt; mehr erfahren</div>
              </div>
              <div class="col-auto align-self-center">
                <img src="assets/images/timetracking.svg">
              </div>
            </div>
          </a>

          <a class="col project text-dark text-decoration-none" routerLink="asp-keycloak" [preserveFragment]="true" routerLinkActive="active">
            <div class="row h-100">
              <div class="col text">
                <h3>KeyCloak ASP.NET</h3>
                <p>Einfache und sichere SSO Authentifizierung.</p>
                <div class="more">&lt; mehr erfahren</div>
              </div>
              <div class="col-auto align-self-center">
                <img src="assets/images/keycloak-asp.svg" class="pe-2">
              </div>
            </div>
          </a>

          <a class="col project text-dark text-decoration-none" routerLink="filter-expression-creator" [preserveFragment]="true" routerLinkActive="active">
            <div class="row h-100">
              <div class="col text">
                <h3>Filter Expression Creator</h3>
                <p>Dynamische Filter für REST und Entity-Framework.</p>
                <div class="more">&lt; mehr erfahren</div>
              </div>
              <div class="col-auto align-self-center">
                <img src="assets/images/filter-expression-creator.svg" style="transform: scaleX(-1);">
              </div>
            </div>
          </a>

        </div>
      </div>
    </div>

  </div>
</div>
