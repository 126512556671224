import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {NavigationComponent} from './components/navigation/navigation.component';
import {MainComponent} from './components/main/main.component';
import {LeadComponent} from './components/main/lead/lead.component';
import {AboutComponent} from './components/main/about/about.component';
import {CompetenceComponent} from './components/main/competence/competence.component';
import {ContactComponent} from './components/main/contact/contact.component';
import {FooterComponent} from './components/footer/footer.component';
import {PrivacyComponent} from './components/privacy/privacy.component';
import {StatementComponent} from './components/privacy/statement/statement.component';
import {LegalComponent} from './components/privacy/legal/legal.component';
import {ProjectsComponent} from './components/main/projects/projects.component';
import {ProjectsTimeTrackingComponent} from "./components/main/projects-time-tracking/projects-time-tracking.component";
import {ProjectsKeycloakAspComponent} from "./components/main/projects-keycloak-asp/projects-keycloak-asp.component";
import {
  ProjectsFilterCreatorComponent
} from "./components/main/projects-filter-creator/projects-filter-creator.component";

@NgModule({
  declarations: [
    AppComponent,
    LeadComponent,
    NavigationComponent,
    AboutComponent,
    ContactComponent,
    PrivacyComponent,
    CompetenceComponent,
    FooterComponent,
    MainComponent,
    StatementComponent,
    LegalComponent,
    ProjectsComponent,
    ProjectsTimeTrackingComponent,
    ProjectsKeycloakAspComponent,
    ProjectsFilterCreatorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
