<div class="container">
  <div class="row">
    <div class="col">
      <div class="title">
        <h1 class="highlight">&Uuml;ber mich</h1>
      </div>
    </div>
  </div>

  <div class="row content">
    <div class="col-24 col-xl-11">
      <div class="about d-flex flex-column justify-content-between h-100">
        <p>
          Mein Name ist Florian Schick. Als Softwareentwickler &uuml;bersetze ich ihre Vision in Programmcode.
          Mit Kommunikation auf Augenh&ouml;he und einem hohen Anspruch an Qualit&auml;t &nbsp;- angefangen beim Konzept bis hin zum finalen Ergebnis.
        </p>
        <p class="text-start">
          Keine Pauschalangebote, <br class="d-inline d-sm-none"><span class="highlight">keine Kompromisse.</span>
          <br>
          <br class="d-sm-none">
          <span class="highlight">Maßgeschneiderte</span> <br class="d-inline d-sm-none">Lösungen von Mensch zu Mensch.
        </p>
        <a routerLink="" fragment="contact" class="btn btn-outline-dark btn-cta align-self-center">
          <img src="assets/icons/chat-dots.svg" alt="check" class="logo-send me-2">
          Erz&auml;hlen Sie mir von Ihrer Vision
        </a>
      </div>
    </div>

    <div class="offset-0 col-24 offset-xl-2 col-xl-11">
      <div class="principles row row-cols-1 row-cols-lg-3 row-cols-xl-1">

        <div class="col w-100 my-principles">
          <div class="row">
            <div class="col-24 col-xl-auto d-none d-xl-block">
            </div>
            <div class="col">
              <h2 class="text-center text-md-start">Meine Coding <span class="highlight">Prinzipien</span></h2>
            </div>
          </div>
        </div>

        <div class="col clean-code mb-5 mb-lg-0 mb-xl-3">
          <div class="row text-center text-md-start text-lg-center text-xl-start">
            <div class="col-24 col-md-auto col-lg-24 col-xl-auto align-self-center align-self-xl-start">
              <img class="check" src="assets/icons/check.svg" alt="check">
            </div>
            <div class="col">
              <h5>Clean Code</h5>
              <p class="mb-0">Lesbarer Code. Einfach zu erweitern.</p>
            </div>
          </div>
        </div>

        <div class="col individual mb-5 mb-lg-0 mb-xl-3">
          <div class="row text-center text-md-start text-lg-center text-xl-start">
            <div class="col-24 col-md-auto col-lg-24 col-xl-auto align-self-center align-self-xl-start">
              <img class="check" src="assets/icons/check.svg" alt="check">
            </div>
            <div class="col">
              <h5>Verst&auml;ndliche Architektur</h5>
              <p class="mb-0">Softwarearchitektur am Puls der Zeit. Damit Sie später keine bösen &Uuml;berraschungen erleben.</p>
            </div>
          </div>
        </div>

        <div class="col automation">
          <div class="row text-center text-md-start text-lg-center text-xl-start">
            <div class="col-24 col-md-auto col-lg-24 col-xl-auto align-self-center align-self-xl-start">
              <img class="check" src="assets/icons/check.svg" alt="check">
            </div>
            <div class="col">
              <h5>Effektive Automatisierung</h5>
              <p class="mb-0">Erreichen Sie mehr mit weniger Aufwand!</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
