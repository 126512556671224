<div class="container">
  <h2 class="text-center text-md-start">Meine wichtigsten <span class="highlight">Kompetenzen</span></h2>

  <div class="competences row gx-3 gx-lg-1 gy-5 row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-6">
    <!-- ASP.NET -->
    <div class="col">
      <div class="card bg-transparent border-0 primary-competence">
        <div class="card-img-top">
          <img src="assets/logos/netcore.green.svg" class="asp-net" alt="ASP.NET">
        </div>
        <div class="card-body">
          <h4 class="card-title">ASP.NET</h4>
          <p class="card-text">Robuste Anwendungen auf Basis von C# .NET</p>
        </div>
      </div>
    </div>

    <!-- Angular -->
    <div class="col">
      <div class="card bg-transparent border-0 primary-competence">
        <div class="card-img-top">
          <img src="assets/logos/angular.svg" class="angular" alt="Angular">
        </div>
        <div class="card-body">
          <h4 class="card-title">Angular</h4>
          <p class="card-text">Moderne und elegante L&ouml;sungen dank Angular</p>
        </div>
      </div>
    </div>

    <!-- Blazor -->
    <div class="col">
      <div class="card bg-transparent border-0 primary-competence">
        <div class="card-img-top">
          <img src="assets/logos/blazor.svg" class="blazor" alt="Blazor">
        </div>
        <div class="card-body">
          <h4 class="card-title">Blazor</h4>
          <p class="card-text">Effiziente Web Apps mit bew&auml;hrter .NET Power</p>
        </div>
      </div>
    </div>

    <!-- Azure -->
    <div class="col">
      <div class="card bg-transparent border-0 secondary-competence">
        <div class="card-img-top">
          <img src="assets/logos/azure.svg" class="azure" alt="Azure">
        </div>
        <div class="card-body">
          <h4 class="card-title">Azure</h4>
          <p class="card-text">Zukunftsweisende Cloud Technologien</p>
        </div>
      </div>
    </div>

    <!-- Docker -->
    <div class="col">
      <div class="card bg-transparent border-0 secondary-competence">
        <div class="card-img-top">
          <img src="assets/logos/docker.svg" class="docker" alt="Docker">
        </div>
        <div class="card-body">
          <h4 class="card-title">Docker</h4>
          <p class="card-text">Portable &amp; skalierbare Container mit Docker</p>
        </div>
      </div>
    </div>

    <!-- HTML5 • CSS3 -->
    <!--
    <div class="col">
      <div class="card bg-transparent border-0 secondary-competence">
        <div class="card-img-top">
          <img src="assets/logos/html5.svg" class="html5" alt="HTML5">
        </div>
        <div class="card-body">
          <h4 class="card-title">HTML5 <span class="text-muted">•</span> CSS3</h6>
          <p class="card-text">Eine catchy Punchline, aber &uuml;ber HTML und CSS</p>
        </div>
      </div>
    </div>
    -->

    <!-- vue.js -->
    <div class="col">
      <div class="card bg-transparent border-0 secondary-competence">
        <div class="card-img-top">
          <img src="assets/logos/vue.js.svg" class="vue-js" alt="vue.js">
        </div>
        <div class="card-body">
          <h4 class="card-title">Vue.js</h4>
          <p class="card-text">Pfeilschnelle Web Apps mit Vue.js</p>
        </div>
      </div>
    </div>
  </div>
</div>
