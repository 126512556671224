<h2>TimeTracking</h2>
<p>
  Digitale Zeiterfassung ist ein aktuelleres Thema denn je, nicht nur für Selbstst&auml;ndige, sondern auch für
  Unternehmen.
  Dabei sind die Werkzeuge daf&uuml;r aber meist kompliziert, &uuml;berladen und teuer.
  TimeTracking ist ein schlankes und effizientes Tool zum Erfassen von Zeiten, das ganz bequem selbst gehostet
  werden kann.
</p>
<div>
  <h4>Features</h4>
  <div class="row">
    <div class="col-auto">
      <img class="check" src="assets/icons/check.svg" alt="check">
    </div>
    <div class="col">
      Zeiten schnell und einfach tracken
    </div>
  </div>
  <div class="row">
    <div class="col-auto">
      <img class="check" src="assets/icons/check.svg" alt="check">
    </div>
    <div class="col">
      detaillierte Analysen und Reports
    </div>
  </div>
  <div class="row">
    <div class="col-auto">
      <img class="check" src="assets/icons/check.svg" alt="check">
    </div>
    <div class="col">
      sicher und datenschutzkonform
    </div>
  </div>
</div>
<div>
  <div class="row screenshots">
    <div class="col-auto cursor-pointer" data-bs-toggle="modal" data-bs-target="#screenshotsModal">
      <img src="assets/screenshots/timetracking-timetable.webp" alt="Zeiterfassung">
    </div>
    <div class="col-auto cursor-pointer" data-bs-toggle="modal" data-bs-target="#screenshotsModal">
      <img src="assets/screenshots/timetracking-chart-customer.webp" alt="Report nach Kunde">
    </div>
    <div class="col-auto cursor-pointer" data-bs-toggle="modal" data-bs-target="#screenshotsModal">
      <img src="assets/screenshots/timetracking-chart-activity.webp" alt="Report nach Tätigkeit">
    </div>
  </div>
</div>


<div class="">
  <a class="btn btn-dark btn-cta me-0 me-sm-4" href="https://demo.timetracking.schick-software.de/" target="_blank">
    <img src="assets/icons/box-arrow-up-right.svg" alt="Live Demo" class="logo-send me-1">
    Live Demo
  </a>
  <br class="d-block d-sm-none">
  <a class="btn btn-outline-dark btn-cta ms-0 ms-sm-4 mt-3 mt-sm-0" href="https://github.com/fschick/TimeTracking" target="_blank">
    <img src="assets/icons/github.svg" alt="Projekt bei GitHub" class="logo-github">
    Projekt bei GitHub
  </a>
</div>

<!-- Screenshot modal -->
<div class="modal fade" id="screenshotsModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">

        <!-- Carousel -->
        <div id="carouselIndicators" class="carousel carousel-dark slide" data-bs-ride="true">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-100" src="assets/screenshots/timetracking-timetable.webp" alt="Zeiterfassung">
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src="assets/screenshots/timetracking-chart-customer.webp"
                   alt="Report nach Kunde">
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src="assets/screenshots/timetracking-chart-activity.webp"
                   alt="Report nach Tätigkeit">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselIndicators"
                  data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Vorheriger</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselIndicators"
                  data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">N&auml;chster</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</div>
