import { Component } from '@angular/core';

@Component({
  selector: 'app-projects-time-tracking',
  templateUrl: './projects-time-tracking.component.html',
  styleUrls: ['./projects-time-tracking.component.scss']
})
export class ProjectsTimeTrackingComponent {

}
