<div class="background">
  <div class="container">
    <div class="contact">
      <div class="title">
        <h1 class="highlight">Kontakt</h1>
        <p>Ich freue mich von Ihnen und Ihrer Idee zu hören.</p>
      </div>

      <div class="row row-cols-1 row-cols-lg-3">
        <div class="col">
          <div class="card h-100 bg-transparent border-0">
            <div class="card-img-top">
              <img src="assets/icons/envelope-at-fill.svg" alt="E-Mail">
            </div>
            <div class="card-body d-flex flex-column justify-content-between">
              <h6>E-Mail</h6>
              <h2> info@schick-software.de </h2>
            </div>
            <div class="card-footer">
              <a class="btn btn-outline-dark btn-cta align-self-center" href="mailto:florian.schick@schick-software.de">
                <img src="assets/icons/envelope-at-fill.svg" alt="E-Mail" class="me-2">
                E-Mail schreiben
              </a>
            </div>
          </div>
        </div>
        <div class="col mt-5 mt-lg-0">
          <div class="card h-100 bg-transparent border-0">
            <div class="card-img-top">
              <img src="assets/icons/telephone-fill.svg" alt="Telefon">
            </div>
            <div class="card-body d-flex flex-column justify-content-between">
              <span>Telefon</span>
              <h2> +49 771 89 79 378</h2>
            </div>
            <div class="card-footer">
              <a class="btn btn-outline-dark btn-cta align-self-center" href="tel:+497718979378">
                <img src="assets/icons/telephone-fill.svg" alt="Telefon" class="me-2">
                Anrufen
              </a>
            </div>
          </div>
        </div>
        <div class="col mt-5 mt-lg-0">
          <div class="card h-100 bg-transparent border-0">
            <div class="card-img-top">
              <img src="assets/icons/linkedin.svg" alt="LinkedIn">
            </div>
            <div class="card-body d-flex flex-column justify-content-between">
              <h6>LinkedIn</h6>
              <h2> Florian Schick</h2>
            </div>
            <div class="card-footer">
              <a class="btn btn-outline-dark btn-cta align-self-center" href="https://www.linkedin.com/in/schick-software/" target="_blank">
                <img src="assets/icons/linkedin.svg" alt="LinkedIn" class="me-2">
                Kontakt
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
