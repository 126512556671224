import { Component } from '@angular/core';

@Component({
  selector: 'app-projects-keycloak-asp',
  templateUrl: './projects-keycloak-asp.component.html',
  styleUrls: ['./projects-keycloak-asp.component.scss']
})
export class ProjectsKeycloakAspComponent {

}
