import { Component } from '@angular/core';

@Component({
  selector: 'app-projects-filter-creator',
  templateUrl: './projects-filter-creator.component.html',
  styleUrls: ['./projects-filter-creator.component.scss']
})
export class ProjectsFilterCreatorComponent {

}
