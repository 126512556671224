<div class="container position-relative g-0 h-100">
  <div class="portrait text-center text-xxl-end">
    <img class="glow" src="/assets/images/florian-schick.webp" alt="Portrait Florian Schick">
    <img src="/assets/images/florian-schick.webp" alt="Portrait Florian Schick">
  </div>
  <div class="lead-text position-relative text-center text-lg-end text-xxl-start">
    <div class="backdrop"></div>

    <div class="slogan">
      <h1 class="d-inline-block fw-semibold me-3">Einzigartige</h1>
      <h1 class="d-inline-block highlight">Software</h1><br>
      <h1 class="d-inline-block fw-semibold">erfordert einzigartige</h1><br>
      <h1 class="d-inline-block highlight">Visionen.</h1>
    </div>

    <div class="call-to-action text-center text-lg-end text-xxl-start">
      <a class="btn btn-dark btn-cta me-0 me-sm-4" routerLink="" fragment="contact">
        <img src="assets/icons/send-fill.svg" alt="Absenden" class="logo-send me-1">
        Projektanfrage
      </a>
      <br class="d-block d-sm-none">
      <a class="btn btn-outline-dark btn-cta ms-0 ms-sm-4 mt-3 mt-sm-0" href="https://github.com/fschick/" target="_blank">
        <img src="assets/icons/github.svg" alt="GitHub" class="logo-github">
        GitHub
      </a>
    </div>
  </div>
</div>
