<h1>Impressum</h1>

<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
<p>Florian Schick<br/>
  Schick Software Entwicklung<br/>
  Wiesenstr. 16<br/>
  78166 Donaueschingen</p>

<h2>Kontakt</h2>
<p>Telefon: +49 771 8979378<br/>
  Telefax: +49 771 8979380<br/>
  E-Mail: info@schick-software.de</p>

<h2>Umsatzsteuer-ID</h2>
<p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br/>
  DE228312685</p>

<h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
<p><strong>Name und Sitz des Versicherers:</strong><br/>
  exali AG<br/>
  Franz-Kobinger-Str. 9<br/>
  86157 Augsburg</p>
<p><strong>Geltungsraum der Versicherung:</strong><br/>Deutschland</p>

<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
