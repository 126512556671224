<div class="container">
  <nav class="navbar navbar-expand-lg fw-bold">
    <a class="navbar-brand flex-grow-1" routerLink="">
      <img class="logo" src="/assets/images/schick-software.svg" alt="Logo Schick Software">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse flex-grow-0 " id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item mt-3 mt-lg-0">
          <a class="nav-link" routerLink="" fragment="about">&Uuml;ber&nbsp;mich</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="" fragment="competence">Kompetenzen</a>
        </li>
        <!--
        <li class="nav-item">
          <a class="nav-link" routerLink="" fragment="projects">Projekte</a>
        </li>
        -->
        <li class="nav-item">
          <a class="nav-link" routerLink="" fragment="contact">Kontakt</a>
        </li>
      </ul>
    </div>
  </nav>
</div>

<span *ngIf="isDevelopment" class="breakpoint">
  <span class="d-inline d-sm-none">&nbsp;(xs)</span>
  <span class="d-none d-sm-inline d-md-none">&nbsp;(sm)</span>
  <span class="d-none d-md-inline d-lg-none">&nbsp;(md)</span>
  <span class="d-none d-lg-inline d-xl-none">&nbsp;(lg)</span>
  <span class="d-none d-xl-inline d-xxl-none">&nbsp;(xl)</span>
  <span class="d-none d-xxl-inline d-xxxl-none">&nbsp;(xxl)</span>
  <span class="d-none d-xxxl-inline">&nbsp;(xxxl)</span>
</span>

<span *ngIf="isDevelopment" class="zoom">
</span>
